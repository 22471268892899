// src/pages/contact.js
import * as React from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import CalendlyWidget from '../components/CalendlyWidget/CalendlyWidget';
import Layout from '../components/layout';
import Seo from '../components/seo';

const NotfoundPage = ({ data }) => {
  return (
    <Layout>
      <Breadcrumb
        crumbs={[
          { pathname: '/', crumbLabel: 'TOP' },
          { pathname: '/meeting', crumbLabel: 'ミーティングの予約' },
        ]}
      />
      <CalendlyWidget />
    </Layout>
  );
};

export default NotfoundPage;

export const Head = () => (
  <Seo
    title="デジタルクリエイティブ株式会社のビデオミーティングの予約"
    description="デジタルクリエイティブ株式会社のビデオミーティングの予約はこちらから。"
  />
);
